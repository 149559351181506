import { Abi, Address, Hash } from 'viem';
import useSmartWallet from '../shared/useSmartWallet';
import useSponsoredTransaction from '../shared/useSponsoredTransaction';
import { useOneCTStore } from '@/store/oneCT';
import { usePublicClient } from 'wagmi';
import { serializeError } from '@/lib/transaction';
import useAppToast from '../shared/useAppToast';
import { noOp } from '@/constants/common';
import { useExecutionQueue } from '@/store/executionQueue';
import { useWriteContract } from 'wagmi';
import { useAccount } from 'wagmi';
import { captureError } from '@/lib/sentry';

const useTransaction = () => {
  const { isSmartWallet } = useSmartWallet();
  const { handleSponsoredTransaction } = useSponsoredTransaction();
  const { privateKey } = useOneCTStore((state) => state);
  const client = usePublicClient();
  const { address: eoaAddress } = useAccount();
  const toast = useAppToast();
  const { push } = useExecutionQueue();
  const { writeContractAsync } = useWriteContract();

  const executeTransaction = async (
    {
      address,
      abi,
      functionName,
      args,
      value = 0n,
      txSuccessCallback = noOp,
      txFailedCallback = noOp,
      feedId = null,
    }: {
      address: Address;
      abi: Abi;
      functionName: string;
      args: any[];
      value?: bigint;
      txSuccessCallback?: () => void;
      txFailedCallback?: () => void;
      priceUpdateRequired?: boolean;
      feedId?: string | null;
    },
    forceEOA = false
  ) => {
    let tx: Hash | undefined = undefined;

    try {
      if (isSmartWallet) {
        toast.txnInitiate();
        tx = await handleSponsoredTransaction({
          address,
          abi,
          functionName,
          args,
          value,
        });
        toast.processing();
      } else if (privateKey && !forceEOA) {
        // since 1CT is entirely managed by us we need to use a queue, not required for regular EOA
        // since wallet manages this
        toast.processing();
        push({
          tx: {
            address,
            abi,
            functionName,
            args,
            value,
            txSuccessCallback,
            txFailedCallback,
          },
          privateKey,
          feedId,
        });
        return true;
      } else {
        toast.txnInitiate();
        await client.simulateContract({
          address,
          abi,
          functionName,
          args,
          value,
          account: eoaAddress,
        });

        tx = await writeContractAsync({
          address,
          abi,
          functionName,
          args,
          value,
        });
        toast.processing();
      }

      const receipt = await client.waitForTransactionReceipt({ hash: tx! });
      if (receipt.status === 'success') {
        toast.txnSuccess(tx!);
        txSuccessCallback?.();
        return true;
      }
      toast.failed('Transaction failed.');
      txFailedCallback?.();

      return false;
    } catch (e) {
      console.log(e);
      const error = serializeError(e);
      captureError(error, {
        eoaAddress,
        address,
        action: 'executeTransaction',
        tx: { address, abi, functionName, args, value },
      });
      toast.failed(error.message);
      return false;
    }
  };

  return { executeTransaction };
};

export default useTransaction;
