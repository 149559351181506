import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

export enum ToastPosition {
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
}

type State = {
  showBanner: boolean;
  skipOpenTradeConfirmations: boolean;
  skipCloseTradeConfirmations: boolean;
  showAnnouncements: boolean;
  showTradeRightPanel: boolean;
  showRecentTradesAndNews: boolean;
  toastPosition: ToastPosition;
};

type Action = {
  updateShowBanner: (value: State['showBanner']) => void;
  updateAnnouncements: (value: State['showAnnouncements']) => void;
  updateOpenTradeConfirmations: (value: State['skipOpenTradeConfirmations']) => void;
  updateCloseTradeConfirmations: (value: State['skipCloseTradeConfirmations']) => void;
  updateShowTradeRightPanel: (value: State['showTradeRightPanel']) => void;
  updateShowRecentTradesAndNews: (value: State['showRecentTradesAndNews']) => void;
  updateToastPosition: (value: ToastPosition) => void;
  reset: () => void;
};

type MyPersist = (
  config: StateCreator<State & Action>,
  options: PersistOptions<State & Action>
) => StateCreator<State & Action>;

const initialState = {
  showBanner: false,
  skipOpenTradeConfirmations: false,
  skipCloseTradeConfirmations: false,
  showAnnouncements: true,
  showTradeRightPanel: false,
  showRecentTradesAndNews: true,
  toastPosition: ToastPosition.TOP_LEFT,
};

export const useUIStore = create<State & Action>(
  (persist as MyPersist)(
    (set) => ({
      ...initialState,
      updateShowBanner: (value) => set(() => ({ showBanner: value })),
      updateAnnouncements: (value) => set(() => ({ showAnnouncements: value })),
      updateOpenTradeConfirmations: (value) => set(() => ({ skipOpenTradeConfirmations: value })),
      updateCloseTradeConfirmations: (value) => set(() => ({ skipCloseTradeConfirmations: value })),
      updateShowTradeRightPanel: (value) => set(() => ({ showTradeRightPanel: value })),
      updateShowRecentTradesAndNews: (value) => set(() => ({ showRecentTradesAndNews: value })),
      updateToastPosition: (value: ToastPosition) => set(() => ({ toastPosition: value })),
      reset: () => set(() => ({ ...initialState })),
    }),
    {
      name: 'avantis-user-settings',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
