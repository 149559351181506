'use client';

import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { ReactNode, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { X } from 'lucide-react';
type Props = {
  className?: string;
  triggerClassName?: string;
  trigger?: ReactNode;
  heading?: string | ReactNode;
  footer?: ReactNode;
  closeCTA?: ReactNode;
  actionCTA?: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
  asClose?: boolean;
  onCleanup?: () => void;
  asChild?: boolean;
  open?: boolean;
  hideClose?: boolean;
};
const ResponsiveDialog = ({
  className,
  trigger,
  triggerClassName,
  heading,
  footer,
  defaultOpen = false,
  onCleanup,
  children,
  open,
  asChild = true,
  hideClose = false
}: Props) => {
  const isTabletOrDesktop = useMediaQuery('(min-width: 768px)');
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const handleCleanup = (open: boolean) => {
    if (!open) {
      onCleanup?.();
    }
  };
  if (!isMounted) {
    return null;
  }
  return <>
      {isTabletOrDesktop && <Dialog defaultOpen={defaultOpen} onOpenChange={handleCleanup} open={open}>
          <DialogDescription className="hidden"></DialogDescription>
          {trigger && <DialogTrigger className={cn('hidden md:flex w-full', triggerClassName)} onClick={e => e.stopPropagation()} asChild={asChild}>
              {trigger}
            </DialogTrigger>}
          <DialogContent className={cn('hidden md:block', className)} onClick={e => e.stopPropagation()}>
            {heading ? <DialogHeader className="flex flex-row items-center justify-between px-8 py-5">
                <DialogTitle>{heading}</DialogTitle>
                {!hideClose && <DialogPrimitive.Close className="opacity-70 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground bg-grey-500 border-grey-400 border rounded-full p-1 h-7 w-7 flex items-center justify-center">
                    <X className="h-4 w-4" strokeWidth={4} />
                    <span className="sr-only">Close</span>
                  </DialogPrimitive.Close>}
              </DialogHeader> : <VisuallyHidden.Root>
                <DialogTitle>title</DialogTitle>
              </VisuallyHidden.Root>}

            <div className="flex flex-col">{children}</div>
            {footer && <DialogFooter className="py-4 px-8">{footer}</DialogFooter>}
          </DialogContent>
        </Dialog>}
      {!isTabletOrDesktop && <Sheet defaultOpen={defaultOpen} onOpenChange={handleCleanup} open={open}>
          <SheetDescription className="hidden"></SheetDescription>
          {trigger && <SheetTrigger className={cn('block md:hidden w-full', triggerClassName)} onClick={e => e.stopPropagation()}>
              {trigger}
            </SheetTrigger>}
          <SheetContent side="bottom" className={cn('md:hidden rounded-t-lg max-h-[90vh] overflow-y-auto', className)} onClick={e => e.stopPropagation()}>
            {heading ? <SheetHeader className="flex flex-row items-center justify-between px-1 py-3 md:py-5">
                <SheetTitle>{heading}</SheetTitle>
                {!hideClose && <DialogPrimitive.Close className="opacity-70 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground bg-grey-500 border-grey-400 border rounded-full p-1 h-7 w-7 flex items-center justify-center">
                    <X className="h-4 w-4" strokeWidth={4} />
                    <span className="sr-only">Close</span>
                  </DialogPrimitive.Close>}
              </SheetHeader> : <VisuallyHidden.Root>
                <SheetTitle>title</SheetTitle>
              </VisuallyHidden.Root>}
            <div className="flex flex-col">{children}</div>
            {footer && <SheetFooter>{footer}</SheetFooter>}
          </SheetContent>
        </Sheet>}
    </>;
};
export default ResponsiveDialog;